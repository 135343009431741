// JavaScript Document

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faBars as farFaBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faTimes as farFaTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faDownload as farFaDownload } from "@fortawesome/pro-regular-svg-icons/faDownload";

import { faFacebookSquare as fabFaFacebookSquare } from "@fortawesome/free-brands-svg-icons/faFacebookSquare";
import { faFacebook as fabFaFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faInstagram as fabFaInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faTwitter as fabFaTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faPinterest as fabFaPinterest } from "@fortawesome/free-brands-svg-icons/faPinterest";
import { faLinkedin as fabFaLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";

/**
 * Add regular icons
 */
library.add(farFaBars, farFaTimes, farFaDownload);

/**
 * Add brand icons
 */
library.add(fabFaFacebookSquare, fabFaFacebook, fabFaInstagram, fabFaTwitter, fabFaLinkedin, fabFaPinterest);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
